import React from "react"
import ReactMarkdown from "react-markdown"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import ResponsiveDiv from "../components/responsivediv"
import SlideShow from "../components/slideshow"
import ColorAccentedTextBar from "../components/color-accented-text-bar"
import InstagramProfilePictures from "../components/instagram-profile-pictures"
import BlogItems from "../components/blog-items"
import Form from "../components/form"
import Pictures from "../components/pictures"
import SocialSharing from "../components/social-sharing"

import * as styles from './article.module.scss'

const Article = ({ data }) => {
    const article = data.strapiArticles
    const socialSharingPicture = article.socialSharingPicture != null ?
        article.socialSharingPicture.childImageSharp.resize.src :
        "";
    const socialSharingPictureWidth = article.socialSharingPicture != null ?
        article.socialSharingPicture.childImageSharp.resize.width :
        "";
    const socialSharingPictureHeight = article.socialSharingPicture != null ?
        article.socialSharingPicture.childImageSharp.resize.height :
        "";

    const allPictures = data.allImageSharp.edges;

    const subHeader = article.subHeader !== null ? <p className="uk-article-meta">{article.subHeader}</p> : null;
    const header = article.header !== null ?
        <ResponsiveDiv>
            <h1 className="uk-article-title">{article.header}</h1>
            {subHeader}
        </ResponsiveDiv>
        : null;

    return (
        <Layout
            title={article.title}
            description={article.description}
            image={socialSharingPicture}
            imageWidth={socialSharingPictureWidth}
            imageHeight={socialSharingPictureHeight}>

            <article className="uk-article">

                {header}

                <ContentItems
                    contentItems={article.content}
                    title={article.title}
                    description={article.description}
                    image={socialSharingPicture}
                    allPictures={allPictures}
                />

            </article>
        </Layout>
    )
}

export const query = graphql`
query ArticleItemQuery($id: Int!) {
    strapiArticles(strapiId: {eq: $id}) {
      title
      url
      description
      header
      subHeader

      content
    }
    allImageSharp {
      edges {
        node {
          gatsbyImageData(
            breakpoints: [100, 200, 400, 800, 1280, 2560]
            formats: WEBP
            placeholder: DOMINANT_COLOR
            layout: FULL_WIDTH
          )
          original {
            src
          }
        }
      }
    }
  }
`

export default Article

const ContentItems = ({ contentItems, title, description, image, allPictures }) => {
    return contentItems.map(contentItem => {
        return (
            <ContentItem key={contentItem.contentType + "-" + contentItem.id} contentItem={contentItem} title={title} description={description} image={image} allPictures={allPictures} />
        )
    })
};

const ContentItem = ({ contentItem, title, description, image, allPictures }) => {
    switch (contentItem.strapi_component) {
        case "articles.rich-text":
            return (
                <ResponsiveDiv>
                    <RichTextPicture picture={contentItem.picture} pictureAlignment={contentItem.pictureAlignment} allPictures={allPictures} />
                    <ReactMarkdown children={contentItem.richText} />
                </ResponsiveDiv>
            )
        case "articles.picture":
            const pictures = contentItem.pictures.map(picture => getPictureFluid(allPictures, picture.hash, picture.name));

            return (
                <ResponsiveDiv>
                    <Pictures pictures={pictures} />
                </ResponsiveDiv>
            )
        case "articles.photo-slideshow":
            return (
                <SlideShow numberOfPhotos={contentItem.numberOfPhotos} />
            )
        case "articles.color-accented-text-bar":
            return (
                <ColorAccentedTextBar text={contentItem.text} rightAlignedImage={contentItem.rightAlignedImage.url} />
            )
        case "articles.instagram-profile-pictures":
            return (
                <div>
                    <InstagramProfilePictures />
                </div>
            )
        case "articles.blog-items":
            return (
                <BlogItems />
            )
        case "articles.form":
            return (
                <Form />
            )
        case "articles.social-sharing":
            return (
                <SocialSharing title={title} description={description} image={image} />
            )
        default:
            return (
                <ResponsiveDiv>
                    <pre>No implementation for contentType "<b>{contentItem.contentType}</b>".</pre>
                </ResponsiveDiv>
            )
    }
}

const RichTextPicture = ({ picture, pictureAlignment, allPictures }) => {
    if (picture == null) return (<></>)

    const pictureFluid = getPictureFluid(allPictures, picture.hash, picture.name);

    return (
        <img src={pictureFluid.images.fallback.src} srcSet={pictureFluid.images.fallback.srcSet} key={picture.id} className={pictureAlignment === "Left" ? styles.imageLeft : styles.imageRight} />
    )
}

function getPictureFluid(allPictures, hash, originalName) {
    const foundPicture = allPictures.find(picture => picture.node.original.src.includes(hash))

    if (foundPicture == null) 
        return null

    foundPicture.node.gatsbyImageData.originalName = originalName
    return foundPicture.node.gatsbyImageData;
}