import React, { useRef, useContext } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from './pictures.module.scss';
import { useScrollMonitor } from 'scrollmonitor-hooks';

import AnalyticsContext from "../context/analytics-context"

const Pictures = ({ pictures }) => {
    return pictures.map((picture, i) => {
        return (
            <Picture picture={picture} key={picture.id} />
        );
    });
};

const Picture = ({ picture }) => {
    const analytics = useContext(AnalyticsContext);
    const ref = useRef(null);
    var startViewing = null;

    useScrollMonitor(ref, {
        fullyEnterViewport: (watcher) => {
            startViewing = performance.now();
        },

        partiallyExitViewport: (watcher) => {
            const timeViewed = Math.round(performance.now() - startViewing);
            if (timeViewed > 200) {
                analytics.postEvent("WatchPicture", {
                    pictureId: watcher.watchItem.attributes['picture-id'].value,
                    timeViewed: timeViewed
                });
            }

            startViewing = null;
        }
    });

    return (
        <div ref={ref} picture-id={picture.originalName}>
            <GatsbyImage
                image={picture}
                key={picture.originalName}
                className={picture.height < 1 ? styles.pictureLandscape : styles.picturePortrait} />
        </div>
    );
}

export default Pictures
