import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import { useForm } from "react-hook-form";
import axios from 'axios';
import querystring from 'querystring';

import ResponsiveDiv from "../components/responsivediv"
import AnalyticsContext from "../context/analytics-context"

const Form = function (data) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const analytics = useContext(AnalyticsContext);
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

    const onSubmit = data => {
        const sendToEmulator = false;
        const postUrl = sendToEmulator
            ? "http://localhost:6001/photography-website-274717/europe-west1/websiteForm"
            : "https://europe-west1-photography-website-274717.cloudfunctions.net/websiteForm";

        setSubmitButtonDisabled(true);

        axios.post(postUrl, querystring.stringify(data),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
            .then((result) => {
                analytics.postEvent("FormSubmit", {});
                navigate("/contact-send");
                setSubmitButtonDisabled(false);
            })
            .catch(function (error) {
                console.error(error);

                setSubmitButtonDisabled(false);
                if (window.confirm('Er is iets mis gegaan tijdens het versturen van het formulier. Opnieuw proberen?')) {
                    onSubmit(data);
                }
            });
    }

    return (
        <ResponsiveDiv>
            <form className="uk-form-horizontal" onSubmit={handleSubmit(onSubmit)}>
                <InputField register={register} errors={errors} id="form-naam" label="Naam" placeHolder="Je naam" validationRule={{ required: true }} />
                <InputField register={register} errors={errors} id="form-email" label="Email" placeHolder="Je email adres" validationRule={{ required: true, pattern: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+).([a-zA-Z]{2,5})$/ }} />
                <InputField register={register} errors={errors} id="form-telefoon" label="Telefoonnummer" placeHolder="Je telefoonnummer" validationRule={{ required: true, pattern: /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/ }} />
                <InputField register={register} errors={errors} id="form-stad" label="Woonplaats" placeHolder="Je woonplaats" validationRule={{ required: true }} />
                <TextAreaField register={register} id="form-data" label="Mogelijke data" placeHolder="Vul hier mogelijke data in voor de fotoshoot" />
                <TextAreaField register={register} id="form-opmerkingen" label="Opmerkingen" placeHolder="Overige opmerkingen" />
                <CheckboxFiled register={register} id="form-eigenkopie" label="Kopie naar eigen email adres sturen" checked />

                <br />
                <input type="submit" className="uk-button uk-button-default" value="Verstuur" aria-label="Verstuur" disabled={submitButtonDisabled} />
            </form>
        </ResponsiveDiv>
    );
}

export default Form

const InputField = ({ register, errors, id, label, placeHolder, validationRule }) => (
    <div className="uk-margin">
        <label className="uk-form-label" htmlFor={id}>{label}</label>
        <div className="uk-form-controls">
            <input className={"uk-input " + (errors[id] && "uk-form-danger")} id={id} type="text" placeholder={placeHolder} {...register(id, validationRule)} aria-label={placeHolder} />
        </div>
    </div>
);

const TextAreaField = ({ register, id, label, placeHolder }) => (
    <div className="uk-margin">
        <label className="uk-form-label" htmlFor={id}>{label}</label>
        <div className="uk-form-controls">
            <textarea className="uk-textarea uk-height-small" id={id} type="text" placeholder={placeHolder} {...register(id)} aria-label={placeHolder} />
        </div>
    </div>
);

const CheckboxFiled = ({ register, id, label, checked }) => (
    <div className="uk-margin">
        <label className="uk-form-label" htmlFor={id}>{label}</label>
        <div className="uk-form-controls">
            <input className="uk-checkbox" id={id} type="checkbox" defaultChecked={checked} {...register(id)} />
        </div>
    </div>
);
