import React from "react"
import ReactMarkdown from "react-markdown"

import ResponsiveDiv from "../components/responsivediv"

import * as styles from './color-accented-text-bar.module.scss'

const ColorAccentedTextBar = (props) => (
    <div className={styles.text}>
        <ResponsiveDiv>
            <img src={props.rightAlignedImage} className={styles.rightAlignedImage} />
            <ReactMarkdown children={props.text} />
        </ResponsiveDiv>
    </div>
)

export default ColorAccentedTextBar
