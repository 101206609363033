import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import ResponsiveDiv from "./responsivediv"

import * as styles from './blog-items.module.scss'

function getBlogItems(data) {
  return data.allStrapiBlogItem.edges.map((blogItem, i) => {
    return (
      <section key={blogItem.node.strapiId} className="uk-transition-toggle">
        <Link to={blogItem.node.article !== undefined ? blogItem.node.article.url : "/blog"}>
          <div className={"uk-card uk-card-default uk-transition-scale-up uk-transition-opaque " + styles.card}>
            <div className="uk-card-media-top">
              <GatsbyImage
                image={blogItem.node.coverPhoto.localFile.childImageSharp.gatsbyImageData}
                alt=""
                className={styles.blogCoverPhoto} />
            </div>
            <div className={"uk-card-body " + styles.cardBody}>
              <h3 className="uk-card-title">{blogItem.node.title}</h3>
            </div>
          </div>
        </Link>
      </section>
    );
  });
}

const BlogItems = () => (
  <StaticQuery
    query={graphql`{
  allStrapiBlogItem(sort: {fields: date, order: DESC}, filter: {}) {
    edges {
      node {
        title
        coverPhoto {
          localFile {
            childImageSharp {
              gatsbyImageData(
                breakpoints: [280, 320, 380, 420, 480, 520, 580]
                formats: WEBP
                placeholder: DOMINANT_COLOR
                layout: FULL_WIDTH
              )
            }
          }
        }
        strapiId
        article {
          url
        }
      }
    }
  }
}
`}
    render={data => (
      <ResponsiveDiv>
        <div className="uk-child-width-1-2@s uk-child-width-1-3@m uk-text-center" uk-grid="true">
          {getBlogItems(data)}
        </div>
      </ResponsiveDiv>
    )}
  />
)

export default BlogItems

