import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { shuffle } from "../util/array-shuffle"

function getSlideShowItems(data) {
  const shuffledBlogItems = shuffle(data.allStrapiBlogItem.nodes);

  return shuffledBlogItems.map((blogItem, i) => {
    return (
      <li key={blogItem.strapiId}>
        <img srcSet={blogItem.coverPhoto.localFile.childImageSharp.gatsbyImageData.images.fallback.srcSet}
          sizes={blogItem.coverPhoto.localFile.childImageSharp.gatsbyImageData.images.fallback.sizes}
          src={blogItem.coverPhoto.localFile.childImageSharp.gatsbyImageData.images.fallback.src}
          alt="" uk-cover="true" />
      </li>
    );
  });
}

const SlideShow = () => (
  <StaticQuery
    query={graphql`query SlideShowQuery {
  allStrapiBlogItem(
    sort: {fields: date, order: DESC}
    limit: 5
    filter: {coverPhoto: {localFile: {publicURL: {ne: null}}}}
  ) {
    nodes {
      coverPhoto {
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: WEBP
              quality: 70
              breakpoints: [800, 1024, 1280, 1366, 1920, 2560]
              placeholder: DOMINANT_COLOR
              layout: FULL_WIDTH
            )
          }
        }
      }
      strapiId
    }
  }
}
`}
    render={data => (
      <>
        <div uk-slideshow="true" autoPlay="true" pause-on-hover="false" ratio="3:2" max-height="1000">
          <div className="uk-position-relative uk-visible-toggle uk-light" tabIndex="-1">

            <ul className="uk-slideshow-items">
              {getSlideShowItems(data)}
            </ul>

            <button className="uk-position-center-left uk-position-small uk-hidden-hover" uk-slidenav-previous="true" uk-slideshow-item="previous" aria-label="Vorige foto"></button>
            <button className="uk-position-center-right uk-position-small uk-hidden-hover" uk-slidenav-next="true" uk-slideshow-item="next" aria-label="Volgende foto"></button>

          </div>

          <ul className="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>

        </div>
      </>
    )}
  ></StaticQuery>
)

export default SlideShow
