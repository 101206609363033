import React from "react"
import { StaticQuery, graphql } from "gatsby"

import ExternalLink from './external-link'

import * as styles from './instagram-profile-pictures.module.scss'

function getInstagramPictures(data, start, end) {
  return data.allInstaNode.nodes.slice(start, end).map((picture, i) => {
    return (
      <ExternalLink to={"https://www.instagram.com/p/" + picture.id + "/"} key={picture.id} >
        <img src={picture.localFile.childImageSharp.gatsbyImageData.src} alt={picture.caption} />
      </ExternalLink>
    );
  });
}

const InstagramProfilePictures = () => (
  <div></div>
)

// TODO: Fix this!

// const InstagramProfilePictures = () => (
//   <StaticQuery
//     query={graphql`
//     {
//       allInstaNode(sort: {fields: likes, order: DESC}, limit: 9, filter: {mediaType: {ne: "GraphVideo"}}) {
//         nodes {
//           caption
//           id
//           mediaType
//           localFile {
//             childImageSharp {
//               fixed(width: 200, height: 200) {
//                 src
//               }
//             }
//           }
//         }
//       }
//     }
//   `}
//     render={data => (
//       <section className={styles.instagramPictures}>
//         <div className="uk-card uk-card-default uk-card-body">
//           <h3 className="uk-card-title">Instagram</h3>
//           <div className="uk-grid-small uk-child-width-expand" uk-grid="true">
//             {getInstagramPictures(data, 0, 3)}
//           </div>
//           <div className="uk-grid-small uk-child-width-expand" uk-grid="true">
//             {getInstagramPictures(data, 3, 6)}
//           </div>
//           <div className="uk-grid-small uk-child-width-expand" uk-grid="true">
//             {getInstagramPictures(data, 6, 9)}
//           </div>
//         </div>
//       </section>
//     )}
//   ></StaticQuery>
// )

export default InstagramProfilePictures
