import React, { useContext } from "react";
import { useLocation } from "@reach/router"
import { StaticQuery, graphql } from "gatsby"

import {
  EmailShareButton,
  FacebookShareButton,
  // InstapaperShareButton,
  // LineShareButton,
  LinkedinShareButton,
  // LivejournalShareButton,
  // MailruShareButton,
  // OKShareButton,
  PinterestShareButton,
  // PocketShareButton,
  // RedditShareButton,
  TelegramShareButton,
  // TumblrShareButton,
  TwitterShareButton,
  // ViberShareButton,
  // VKShareButton,
  // WeiboShareButton,
  WhatsappShareButton,
  // WorkplaceShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  // FacebookMessengerIcon,
  // InstapaperIcon,
  // LineIcon,
  LinkedinIcon,
  // LivejournalIcon,
  // MailruIcon,
  // OKIcon,
  PinterestIcon,
  // PocketIcon,
  // RedditIcon,
  TelegramIcon,
  // TumblrIcon,
  TwitterIcon,
  // ViberIcon,
  // VKIcon,
  // WeiboIcon,
  WhatsappIcon,
  // WorkplaceIcon
} from "react-share";

import ResponsiveDiv from "../components/responsivediv"
import AnalyticsContext from "../context/analytics-context"

import * as styles from "./social-sharing.module.scss"

const SocialSharing = ({ title, description, image }) => {
  const iconSize = 32;
  const round = true;
  const location = useLocation();
  const analytics = useContext(AnalyticsContext);

  function handleSocialSharingButtonClick(e) {
    const clickedButton = e.currentTarget;
    analytics.postEvent("SocialSharingButtonClick", {
      media: clickedButton.getAttribute("media"),
      url: e.currentTarget.getAttribute("url")
    });
  }

  return (<StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `}
    render={data => {
      const sharingUrl = data.site.siteMetadata.siteUrl + location.pathname;
      return (
        <ResponsiveDiv >
          <div className={styles.sharingButtons}>

            <span className={styles.sharingText}>Delen via sociale media : </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Email">
              <EmailShareButton url={sharingUrl} subject={title} className={styles.shareButton} >
                <EmailIcon className={styles.shareIcon} size={iconSize} round={round} />
              </EmailShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Facebook">
              <FacebookShareButton url={sharingUrl} className={styles.shareButton}>
                <FacebookIcon className={styles.shareIcon} size={iconSize} round={round} />
              </FacebookShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Instapaper">
              {/* <InstapaperShareButton url={sharingUrl} title={title} description={description} className={styles.shareButton}>
              <InstapaperIcon className={styles.shareIcon} size={iconSize} round={round} />
            </InstapaperShareButton> */}
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Linkedin">
              <LinkedinShareButton url={sharingUrl} title={title} summary={description} source="Peter Fortuin Fotografie" className={styles.shareButton}>
                <LinkedinIcon className={styles.shareIcon} size={iconSize} round={round} />
              </LinkedinShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Line">
              {/* <LineShareButton url={sharingUrl} title={title} className={styles.shareButton}>
              <LineIcon className={styles.shareIcon} size={iconSize} round={round} />
            </LineShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Livejournal">
            <LivejournalShareButton url={sharingUrl} title={title} description={description} className={styles.shareButton}>
              <LivejournalIcon className={styles.shareIcon} size={iconSize} round={round} />
            </LivejournalShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Mailru">
            <MailruShareButton url={sharingUrl} title={title} description={description} className={styles.shareButton}>
              <MailruIcon className={styles.shareIcon} size={iconSize} round={round} imageUrl={image} />
            </MailruShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Ok">
            <OKShareButton url={sharingUrl} title={title} description={description} className={styles.shareButton}>
              <OKIcon className={styles.shareIcon} size={iconSize} round={round} image={image} />
            </OKShareButton>
            </span> */}

              <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Pinterest"></span>
              <PinterestShareButton url={sharingUrl} media={image} description={description} className={styles.shareButton}>
                <PinterestIcon className={styles.shareIcon} size={iconSize} round={round} />
              </PinterestShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Pocket">
              {/* <PocketShareButton url={sharingUrl} title={title} className={styles.shareButton}>
              <PocketIcon className={styles.shareIcon} size={iconSize} round={round} />
            </PocketShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Reddit">
            <RedditShareButton url={sharingUrl} title={title} className={styles.shareButton}>
              <RedditIcon className={styles.shareIcon} size={iconSize} round={round} />
            </RedditShareButton>
            */}</span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Telegram">
              <TelegramShareButton url={sharingUrl} title={title} className={styles.shareButton}>
                <TelegramIcon className={styles.shareIcon} size={iconSize} round={round} />
              </TelegramShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Tumblr">
              {/* <TumblrShareButton url={sharingUrl} title={title} caption={description} posttype="link" className={styles.shareButton}>
              <TumblrIcon className={styles.shareIcon} size={iconSize} round={round} />
            </TumblrShareButton> */}
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Twitter">
              <TwitterShareButton url={sharingUrl} title={title} className={styles.shareButton}>
                <TwitterIcon className={styles.shareIcon} size={iconSize} round={round} />
              </TwitterShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Viber">
              {/* <ViberShareButton url={sharingUrl} title={title} className={styles.shareButton}>
              <ViberIcon className={styles.shareIcon} size={iconSize} round={round} />
            </ViberShareButton>
</span>
            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="VK">
            <VKShareButton url={sharingUrl} title={title} image={image} className={styles.shareButton}>
              <VKIcon className={styles.shareIcon} size={iconSize} round={round} />
            </VKShareButton>
</span>
            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Weibo">
            <WeiboShareButton url={sharingUrl} title={title} image={image} className={styles.shareButton}>
              <WeiboIcon className={styles.shareIcon} size={iconSize} round={round} />
            </WeiboShareButton> */}
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Whatsapp">
              <WhatsappShareButton url={sharingUrl} title={title} className={styles.shareButton}>
                <WhatsappIcon className={styles.shareIcon} size={iconSize} round={round} />
              </WhatsappShareButton>
            </span>

            <span onClick={handleSocialSharingButtonClick} url={sharingUrl} media="Workplace">
              {/* <WorkplaceShareButton url={sharingUrl} className={styles.shareButton}>
              <WorkplaceIcon className={styles.shareIcon} size={iconSize} round={round} />
            </WorkplaceShareButton> */}
            </span>
          </div>
        </ResponsiveDiv >)
    }}
  ></StaticQuery >

  );
}

export default SocialSharing
